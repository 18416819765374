import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PlayStoreIcon from "./play.png";

import AppStoreIcon from "./aple.png";
import "./screenshots.css";

const Screenshots = ({ referComponent }) => {
  return (
    <div className="ss-parent">
      <Row>
        <Col md={6}>
          <div className="ss-heading">
            {referComponent
              ? "Refer and get free services"
              : "Professionals just a click away!"}
          </div>
          <div className="ss-sub-heading">
            {!referComponent && "Step towards comfort with us."}
          </div>
          <div className="ss-sub-text">
            {referComponent
              ? "Invite your friends to Chicago Green Cleaners. They get Exclusive Offers. You will also get a chance to win $500 worth of Dry Cleaning services. "
              : "Sign in through our app and avail all special offers with a 30 day quality assurance."}
          </div>
        </Col>
        <Col md={3}>
          <div
            className="store-icons"
            style={{ margin: "20px auto" }}
            onClick={() => window.open("https://www.google.com/", "_blank")}
          >
            <img
              width="65%"
              height="100%"
              src={PlayStoreIcon}
              alt="play_store_icon"
              style={{ marginRight: "10px", cursor: "pointer" }}
            />
          </div>
        </Col>
        <Col md={3}>
          {" "}
          <div
            className="store-icons"
            style={{ margin: "20px auto" }}
            onClick={() => window.open("https://www.google.com/", "_blank")}
          >
            <img
              width="60%"
              height="100%"
              className="appImg"
              src={AppStoreIcon}
              alt="play_store_icon"
              style={{ marginRight: "10px", cursor: "pointer" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Screenshots;
