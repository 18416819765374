import React from 'react';
import './wpicon.css'
const WpIcon = () => {
  return <div>
    <div className="floating-btn">
        <a href="https://wa.me/+13125059138"><i class="fab fa-whatsapp textIc" > </i></a>
        </div>
  </div>;
};

export default WpIcon;
