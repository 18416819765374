import React from 'react'
import "./Service.scss"
import { useScroll } from "../useScroll/useScroll";
import { motion } from 'framer-motion/dist/framer-motion'
import { Container, Col, Row } from "react-bootstrap";
import { cardAnimation, textAnimation } from "../../utils/Animations";
import img1 from './service1.png'
import img2 from './service2.png'
import img3 from './service3.png'
import img4 from './service4.png'
import img5 from './service5.png'
import img6 from './service6.png'
import img7 from './service7.png'
import img8 from './service8.png'
import img9 from './service9.png'
const Work1 = () => {
    const [element, controls] = useScroll();
    return (
        <div>
        <div className='workHead788'><h2>Our Services</h2></div>
        <div className='danny788'>
            
            {/* <img src={img8} alt="" className='hd' style={{ width: "100%" }} /> */}
            <div className="no788"style={{padding:"22px"}}>
            {/* <motion.div
            variants={cardAnimation}
            animate={controls}
            transition={{ duration: 0.5 }}> */}


                <Row className='tro788' ref={element}>
                    {/* <motion.div variants={textAnimation}
                        animate={controls}
                        transition={{ duration: 1 }}> */}
                    <Col className='lp788'>

                        
                        {/* <p className='ptext'>Book your laundry service in just a few clicks . Dry Cleaning made easy with Chicago Green Dry Cleaners.</p> */}
                    </Col>
                    {/* </motion.div> */}
                </Row>

                <Row className='zz11' style={{textAlign:"center"}}>

                    <Col className='ll788' >
                    <img src={img1} alt="" className='hd788'  />
                        <h4 className='text-center788'>Dry Cleaning</h4>
                        <p className='workP788'>Our process is tough on stains and odors but gentle on your clothes and the environment.</p>
                    </Col>


                    <Col className='ll788'>
                    <img src={img2} alt="" className='hd788'  />
                        <h4 className='text-center788'>Laundry</h4>
                        <p className='workP788'>We specialize in laundering shirts, blouses, pants, and jeans! You may choose light, medium, or heavy starch, as well as pick them up hung or have them boxed for travel convenience.</p>            </Col>

                    <Col className='ll788'>
                        <img src={img3} alt="" className='hd788'  />
                        <h4 className='text-center788'>Leathers and Suede</h4>
                        <p className='workP788'>We deliver your items cleaned within 24 hours and at the time required.</p>
                    </Col>
                    </Row>
                    <Row className='zz11' style={{textAlign:"center"}}>
                    <Col className='ll788'>
                        <img src={img4} alt="" className='hd788'  />
                        <h4 className='text-center788'>Alteration</h4>
                        <p className='workP788'>We can mend, repair, or make adjustments to existing garments. Our staff can make the alterations you need both quickly and affordably.</p>
                    </Col>
                    
                  
                    <Col className='ll788'>
                        <img src={img5} alt="" className='hd788'  />
                        <h4 className='text-center788'>Linen and House holds</h4>
                        <p className='workP788'>We specialize in cleaning Tablecloths, Curtains/Drapes, Comforters, Bedspreads, Pillows, and even your favorite stuffed animals!</p>
                    </Col>
                    <Col className='ll788'>
                        <img src={img6} alt="" className='hd788'  />
                        <h4 className='text-center788'>Wash and Fold</h4>
                        <p className='workP788'>It's just like doing your household laundry, except we do it for you. Your clothes are washed, dried, and neatly folded.</p>
                    </Col>
                    </Row>
                    <Row className='zz11' style={{textAlign:"center"}}>
                    <Col className='ll788'>
                        <img src={img7} alt="" className='hd788'  />
                        <h4 className='text-center788'>Press Only</h4>
                        <p className='workP788'>Press only is a great alternative to dry cleaning when you have clothing that just needs a touch up. Your clothes will be steam pressed and look like new again.</p>
                    </Col>
                    <Col className='ll788'>
                        <img src={img8} alt="" className='hd788'  />
                        <h4 className='text-center788'>Wedding Gowns and Dresses</h4>
                        <p className='workP788'>We keep your dress looking as beautiful as the day you wore it. We preserve your beautiful dress as a keepsake or to share with your daughter on her wedding day.</p>
                    </Col>
                    <Col className='ll788'>
                        <img src={img9} alt="" className='hd788'  />
                        <h4 className='text-center788'>Rug Cleaning</h4>
                        <p className='workP788'>Your rugs will look like new after we thoroughly clean them by a powerful, but gentle process.  If repairs are needed, we offer a wide range of services to alter or restore your rug.</p>
                    </Col>
                    </Row>
                {/* </motion.div> */}
            </div>
        
    </div>
        </div>
        
    )
}

export default Work1
