import react from 'react';
import './Search1.css'
const Search1 = () => {
    return(
        <>
        <div className="searchheight1">
        <div className="searchtxt1">You can find us by Searching</div>
        <div class="conttainerr1">
    <div class="boxx1">
      <p>Dry Cleaners in Chicago<br/>
      green dry cleaners near me<br/>
      eco friendly dry cleaners near me<br/>
      best green dry cleaners near me<br/>
      green dry cleaning at home<br/>
      green dry cleaning chemicals<br/>
      chemical free dry cleaners near me</p>
    </div>

    <div class="boxx1">
    <p>Dry Cleaners in Chicago<br/>
      green dry cleaners near me<br/>
      eco friendly dry cleaners near me<br/>
      best green dry cleaners near me<br/>
      green dry cleaning at home<br/>
      green dry cleaning chemicals<br/>
      chemical free dry cleaners near me</p>
    </div>

    <div class="boxx1">
      <p>Dry Cleaners in Chicago<br/>
      green dry cleaners near me<br/>
      eco friendly dry cleaners near me<br/>
      best green dry cleaners near me<br/>
      green dry cleaning at home<br/>
      green dry cleaning chemicals<br/>
      chemical free dry cleaners near me</p>
    </div>

    <div class="boxx1">
    <p>Dry Cleaners in Chicago<br/>
      green dry cleaners near me<br/>
      eco friendly dry cleaners near me<br/>
      best green dry cleaners near me<br/>
      green dry cleaning at home<br/>
      green dry cleaning chemicals<br/>
      chemical free dry cleaners near me</p>
    </div>    
    </div>
    </div>
        </>
    );
}
export default Search1;